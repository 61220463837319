export const AstroPayStyles = `


#astro-container{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  transition: all .3s ease;
}

.astro-close-button{

}

#astro-iframe-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none;
  background: white;
  box-shadow: 0px 4px 17px 12px rgb(0 0 0 / 10%);
  height: 100%;

 
}
@media only screen and (min-width: 600px) {
  #astro-iframe-wrapper{
    max-width: 400px;
    height: 100%;
    max-height: 600px;
  }
}

#astro-iframe {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
  border-radius: 8px;
}

#astro-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 1.5rem;
  background-color: white;
  border:none;
  cursor: pointer;
  border-radius: 100%;
}
`;
