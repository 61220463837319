import { AstroPayStyles } from '../astropay-style';
import {InitConfig} from "./SDKMethods";
export class ElementsCreator {
  private static iFrameWrapper: any;
  private static container: any;

  public static createOneTouchContainer(oneTouchUrl: string, closeCallback: any, sdkConfig: InitConfig) {
    const styleElem = document.createElement('style');
    styleElem.innerHTML = AstroPayStyles;

    const container = document.createElement('div');
    container.setAttribute(`id`, `astro-container`);
    container.style.zIndex = sdkConfig.zIndex || '999';

    container.addEventListener('click', () => {
      if (sdkConfig.backdrop === 'active') {
          // Remove self node
          this.close();
          closeCallback();
      }
    });

    const iFrameWrapper = document.createElement('div');
    iFrameWrapper.setAttribute(`id`, `astro-iframe-wrapper`);
    iFrameWrapper.setAttribute('class', 'astro-iframe-wrapper');

    const iFrame = document.createElement('iframe');
    iFrame.setAttribute(`id`, `astro-iframe`);

    // If is mobile, set width to 100%
    if (window.innerWidth < 500) {
      iFrame.setAttribute(`class`, `astropay-mobile`);
    } else {
      iFrame.setAttribute(`class`, `astropay-desktop`);
    }

    const closeButton = document.createElement('button');
    closeButton.setAttribute(`id`, `astro-close-button`);
    closeButton.setAttribute(`class`, `astropay-close-button`);
    closeButton.innerHTML = '&times;';
    closeButton.addEventListener('click', (e) => {
      // Remove iFrame Wrapper
      e.preventDefault();
      e.stopPropagation();
      this.close();
      closeCallback();
    });

    iFrame.src = oneTouchUrl;
    container.appendChild(iFrameWrapper);
    iFrameWrapper.appendChild(iFrame);
    iFrameWrapper.appendChild(closeButton);
    document.body.appendChild(container);
    document.head.appendChild(styleElem);

    this.iFrameWrapper = iFrameWrapper;
    this.container = container;
  }

  public static close() {
    this.iFrameWrapper.parentNode?.removeChild(this.iFrameWrapper);
    this.container.parentNode?.removeChild(this.container);
  }
}
