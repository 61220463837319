export type AstroPayEnvs = 'test' | 'sandbox' | 'production';

export const DefaultEnv: AstroPayEnvs = 'production';

const BASE_ONETOUCH_URL: Record<AstroPayEnvs, string> = {
  test: 'https://deposit-tst.astropay.com',
  sandbox: 'https://onetouch-sandbox.astropay.com',
  production: 'https://onetouch.astropay.com',
};

export default BASE_ONETOUCH_URL;
