import { LogService } from './LogService';
import { ElementsCreator } from './elementsCreator';
import BASE_ONETOUCH_URL, { AstroPayEnvs, DefaultEnv } from './env';
import { getIsSafari } from './helpers';

export type OpenMethods = 'window' | 'iframe';

export type InitConfig = {
  environment?: AstroPayEnvs;
  language?: string;
  debug?: boolean;
  onDepositStatusChange?: Function;
  onClose?: Function;
  backdrop?: 'static' | 'active';
  zIndex?: string;
};

export type DepositResponse = {
  status: DepositStatus;
  depositExternalId: string;
  url?: string;
  error?: string;
  description?: string;
};

export enum DepositStatus {
  'UNDEFINED',
  'PENDING',
  'APPROVED',
  'CANCELLED',
  'PURCHASE_PENDING',
}

export class AstroPaySdkClass {
  private lastDepositStatus: DepositResponse = {
    depositExternalId: '',
    status: DepositStatus.UNDEFINED,
  };
  private isMobile: boolean = false;

  public config: InitConfig = {
    environment: DefaultEnv,
    debug: false,
    backdrop: 'static',
    zIndex: 'auto',
  };

  public init = (appId: string, config?: InitConfig) => {
    this.config = { ...this.config, ...config };
    if (this.config.debug) {
      (window as any).AstroPayDebug = true;
    }
    this.isMobile = AstroPaySdkClass.detectDevice();
    LogService.consoleLog('AstroPay SDK - init');

    window.addEventListener('message', this.handleMessageEvent, false);
  };

  private handleMessageEvent = (event: any) => {
    if (event.origin !== BASE_ONETOUCH_URL[this.config?.environment ?? DefaultEnv]) return;
    this.lastDepositStatus = {
      status: (<any>DepositStatus)[event.data?.depositStatusNotify.status],
      depositExternalId: event.data?.depositStatusNotify.depositExternalId,
    };
    switch (event.data?.action) {
      case 'close':
        ElementsCreator.close();
        this.callCloseCallback();
        this.removeMessageEventListener();
        break;
      case 'onDepositResponse':
        this.config?.onDepositStatusChange &&
          this.config?.onDepositStatusChange(this.lastDepositStatus);
        break;
    }
  };

  private callCloseCallback = () => {
    this.config?.onClose && this.config?.onClose(this.lastDepositStatus);
  };

  private removeMessageEventListener = () => {
    window.removeEventListener('message', this.handleMessageEvent);
  };

  private showPayment = (externalDepositId: string) => {
    this.lastDepositStatus = { depositExternalId: '', status: DepositStatus.UNDEFINED };
    const url = this.buildUrl(externalDepositId);
    const openMethod = this.getOpenMethod();
    ElementsCreator.createOneTouchContainer(url, this.callCloseCallback, this.config);

    LogService.consoleLog(
      `AstroPay SDK - Show Payment - ${openMethod === 'iframe' ? 'Inside' : 'Outside'} - ${url}`,
    );
  };

  public showDeposit = (externalDepositId: string) => {
    return this.showPayment(externalDepositId);
  };

  private buildUrl = (token: string) => {
    const baseUrl = BASE_ONETOUCH_URL[this.config?.environment ?? 'production'];
    const url = `${baseUrl}/deposit/${token}`;
    let qs = `condensed=true`;
    if (this.config?.language) {
      qs += `&language=${this.config.language}`;
    }
    qs += `&mobile=${this.isMobile}`;

    const join = url.includes('?') ? '&' : '?';
    return `${url}${join}${qs}`;
  };

  private getOpenMethod = (): OpenMethods => {
    if (getIsSafari()) {
      return 'window';
    } else {
      return 'iframe';
    }
  };

  private static detectDevice(): boolean {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform)
    );
  }
}

const AstroPaySDKInstance = new AstroPaySdkClass();
export default AstroPaySDKInstance;
